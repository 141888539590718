/**
 * Can be used to check for conditions that should never happen.
 * Particularly useful when the alternative is to use an `!` assertion.
 * To provide a better error message, describe _why_ the condition should never happen.
 * Example:
 * (Bad): 'Campaign should not be undefined'
 * (Good): 'Campaign should exist after successful snapshot'
 */
export function invariant(condition: unknown, message?: string): asserts condition {
  if (condition) {
    return
  }

  const prefix = 'Invariant failed'

  const value = message ? `${prefix}: ${message}` : prefix
  throw new InvariantError(value)
}

export class InvariantError extends Error {
  constructor(msg: string) {
    super(msg)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvariantError.prototype)
  }
}
